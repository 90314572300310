.join_page{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #161616;
  }
  
  main h1{
    color: #afafaf;
  }
  main h2{
    color: #ebebeb;
    font-size: larger;
  }
  
  